const environment = {
  production: false,
  // create: ,
  // readId: ,
  readAllBurgers:
    'https://us-central1-toronto-burgers.cloudfunctions.net/app/api/burgers',
  readAllRestaurants:
    'https://us-central1-toronto-burgers.cloudfunctions.net/app/api/restaurants',
  // update: ,
  // delete:
}

export default environment

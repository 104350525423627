import React from 'react'
import PropTypes from 'prop-types'
import 'fontsource-roboto'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  headerContent: {
    backgroundColor: '#d7a26c',
    color: '#000000',
  },
}))

const Header = ({ text }) => {
  const classes = useStyles()

  return (
    <AppBar position="relative">
      <Toolbar className={classes.headerContent}>
        <Typography variant="h6" color={classes.heroContent} noWrap>
          {text}
        </Typography>
      </Toolbar>
    </AppBar>
  )
}

Header.propTypes = {
  text: PropTypes.string,
}

Header.defaultProps = {
  text: '',
}

export default Header

import React from 'react'
import PropTypes from 'prop-types'
import 'fontsource-roboto'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Link from '@material-ui/core/Link'

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: '#d7a26c',
    color: '#000000',
    padding: theme.spacing(6),
  },
}))

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Toronto Burgers
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

const Footer = ({ title, description }) => {
  const classes = useStyles()

  return (
    <footer className={classes.footer}>
      {/* <Typography variant="h6" align="center" gutterBottom>
        {title}
      </Typography> */}
      {/* <Typography
        variant="subtitle1"
        align="center"
        color="textSecondary"
        component="p"
      >
        {description}
      </Typography> */}
      <Copyright />
    </footer>
  )
}

Footer.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}

Footer.defaultProps = {
  title: '',
  description: '',
}

export default Footer

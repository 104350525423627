import React, { useState } from 'react'
import 'fontsource-roboto'

import CssBaseline from '@material-ui/core/CssBaseline'
// import { makeStyles } from '@material-ui/core/styles'

import Header from './components/Header'
import Hero from './components/Hero'
import Footer from './components/Footer'
import Burgers from './components/body/Burgers'
import Restaurants from './components/body/Restaurants'

// const useStyles = makeStyles((theme) => ({}))

function App() {
  // const classes = useStyles()

  const [view, setView] = useState('burgers')

  const clickFirst = () => {
    setView('burgers')
  }

  const clickSecond = () => {
    setView('restaurants')
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Header text="Toronto Burgers" />
      <main>
        <Hero
          title="Toronto Burgers"
          description="Welcome to Toronto's comprehensive database of all the burgers that exist in this great city."
          firstButtonText="Burgers"
          secondButtonText="Restaurants"
          firstButtonClick={() => clickFirst()}
          secondButtonClick={() => clickSecond()}
        />
        {view === 'burgers' ? <Burgers /> : <Restaurants />}
      </main>
      <Footer
        title="Toronto Burgers"
        description="One stop shop for Toronto burger info!"
      />
    </React.Fragment>
  )
}

export default App

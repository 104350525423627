import React, { useEffect, useState } from 'react'
// import PropTypes from 'prop-types'
import 'fontsource-roboto'

import Box from '@material-ui/core/Box'
// import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
// import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

import environment from '../../environments.js'

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    backgroundColor: '#592a20',
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
    paddingBottom: '0px',
    '&:last-child': {
      paddingBottom: '16px',
    },
  },
}))

const Burgers = () => {
  const [hasError, setErrors] = useState(false)
  const [burgers, setBurgers] = useState([])

  useEffect(() => {
    async function fetchData() {
      const res = await fetch(environment.readAllBurgers)
      res
        .json()
        .then((res) => {
          console.log('res', res)
          setBurgers(res)
        })
        .catch((err) => setErrors(err))
    }

    fetchData()
  }, [])

  const classes = useStyles()

  return (
    <Container className={classes.cardGrid} maxWidth="xl">
      <Grid container spacing={4}>
        {burgers.map((burger, index) => (
          <Grid item key={burger + index} xs={12}>
            <Card className={classes.card}>
              {/* <CardMedia
                className={classes.cardMedia}
                image="https://source.unsplash.com/random"
                title="Image title"
              /> */}
              <CardContent className={classes.cardContent}>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                >
                  <Box width="85%">
                    <Typography
                      // gutterBottom
                      variant="h5"
                      component="h2"
                    >
                      {burger.name}
                    </Typography>
                  </Box>
                  <Box width="15%">
                    <Typography>{`Patties: ${burger.patties}`}</Typography>
                  </Box>
                </Box>
              </CardContent>
              {/* <CardActions>
                <Button size="small" color="primary">
                  View
                </Button>
                <Button size="small" color="primary">
                  Edit
                </Button>
              </CardActions> */}
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}

// Burgers.propTypes = {}

// Burgers.defaultProps = {}

export default Burgers

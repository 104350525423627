import React, { useEffect, useState } from 'react'
// import PropTypes from 'prop-types'
import 'fontsource-roboto'

import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

import environment from '../../environments.js'

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    backgroundColor: '#592a20',
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  imageContainer: {
    paddingTop: '1rem',
    height: 'auto',
    maxHeight: '250px',
    width: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cardImage: {
    height: '250px',
  },
  cardContent: {
    flexGrow: 1,
  },
  viewButton: {
    size: 'small',
    color: 'primary',
  },
  websiteLink: {
    size: 'small',
    color: 'primary',
    underline: 'none',
    '&:hover, &:focus': {
      underline: 'none',
    },
  },
}))

const Restaurants = () => {
  const [hasError, setErrors] = useState(false)
  const [restaurants, setRestaurants] = useState([])

  useEffect(() => {
    async function fetchData() {
      const res = await fetch(environment.readAllRestaurants)
      res
        .json()
        .then((res) => {
          console.log('res', res)
          setRestaurants(res)
        })
        .catch((err) => setErrors(err))
    }

    fetchData()
  }, [])

  const classes = useStyles()

  return (
    <Container className={classes.cardGrid} maxWidth="xl">
      <Grid container spacing={4} justify="center">
        {restaurants.map((restaurant, index) => (
          <Grid item key={restaurant + index} xs={12} sm={6} md={3}>
            <Card className={classes.card}>
              <div className={classes.imageContainer}>
                <img
                  src={restaurant.restaurantLogo}
                  alt={restaurant.name}
                  className={classes.cardImage}
                />
              </div>
              <CardContent className={classes.cardContent}>
                <Typography gutterBottom variant="h5" component="h2">
                  {restaurant.name}
                </Typography>
                {/* <Typography>
                  This is a media card. You can use this section to
                  describe the content.
                </Typography> */}
              </CardContent>
              <CardActions>
                <Button
                  className={classes.viewButton}
                  variant="contained"
                >
                  View Burgers
                </Button>
                <Button href={restaurant.website}>Website</Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}

// Restaurants.propTypes = {}

// Restaurants.defaultProps = {}

export default Restaurants

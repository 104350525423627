import React from 'react'
import PropTypes from 'prop-types'
import 'fontsource-roboto'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: '#ffd867',
    padding: theme.spacing(8, 0, 6),
    color: '#000000',
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  burgerButton: {
    color: '#ffffff',
    backgroundColor: '#cc0000',
    '&:hover, &:focus': {
      backgroundColor: '#b70000',
    },
  },
  restaurantButton: {
    color: '#ffffff',
    backgroundColor: '#44970c',
    '&:hover, &:focus': {
      backgroundColor: '#3d870a',
    },
  },
}))

const Hero = ({
  title,
  description,
  firstButtonText,
  secondButtonText,
  firstButtonClick,
  secondButtonClick,
}) => {
  const classes = useStyles()

  return (
    <div>
      <Container maxWidth="xl" className={classes.heroContent}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="inherit"
          gutterBottom
        >
          {title}
        </Typography>
        <Typography
          variant="h5"
          align="center"
          color="textSecondary"
          paragraph
        >
          {description}
        </Typography>
        <div className={classes.heroButtons}>
          <Grid container spacing={2} justify="center">
            <Grid item>
              <Button
                variant="contained"
                className={classes.burgerButton}
                onClick={() => firstButtonClick()}
              >
                {firstButtonText}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                className={classes.restaurantButton}
                onClick={() => secondButtonClick()}
              >
                {secondButtonText}
              </Button>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  )
}

Hero.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  firstButtonText: PropTypes.string,
  secondButtonText: PropTypes.string,
  firstButtonClick: PropTypes.func.isRequired,
  secondButtonClick: PropTypes.func.isRequired,
}

Hero.defaultProps = {
  title: '',
  description: '',
  firstButtonText: '',
  secondButtonText: '',
}

export default Hero
